import createSalaryComponent from './mutations/createSalaryComponent';
import updateComponentStatus from './mutations/updateComponentStatus';
import updateSalaryComponent from './mutations/updateSalaryComponent';

const mutations = {
  updateComponentStatus,
  updateSalaryComponent,
  createSalaryComponent,
};

export default mutations;
