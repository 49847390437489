import { deductFrom, deductionType } from 'components/WizardViews/SalaryComponents/constants';
import { DeductionsComponentType, EarningsComponentType } from '../types';

export const EarningComponentsList: EarningsComponentType[] = Array.from({ length: 25 }, (_, i) => {
  if (i % 3 === 0) {
    return {
      id: i.toString(),
      name: 'Basic',
      displayName: 'Basic',
      description:
        'The basic salary is the base income of an employee, comprising of 35-50% of the total salary.',
      category: 'EARNINGS_CATEGORY',
      isActive: true,
      source: 'salary_structure',
      settings: {
        behaviour: {
          pfBehaviour: { includeInPfWages: true },
          esiBehaviour: { includeInEsiWages: false },
          ptBehaviour: { includeInPtWages: false },
          lwfBehaviour: { includeInLwfWages: false },
          taxBehaviour: {
            taxExemptInOldRegime: true,
            taxExemptInNewRegime: false,
            taxExemptionSection: 'Section 10',
          },
          arrearBehaviour: {
            considerInArrear: false,
            allowNegative: false,
          },
          prorationBehaviour: {
            supportProration: true,
          },
          displayBehaviour: {
            showZero: false,
            visibleToEmployee: true,
            displayName: 'Basic',
          },
        },
        earningDetails: {
          payType: 'EARNING_FIXED_TYPE',
        },
      },
    };
  } else if (i % 3 === 1) {
    return {
      id: i.toString(),
      name: 'LTA',
      displayName: 'Leave Travel Allowance',
      description:
        'Leave Travel Allowance (LTA) is a type of allowance that is provided by employers to employees to cover their travel expenses when they are on leave from work.',
      category: 'EARNINGS_CATEGORY',
      isActive: true,
      source: 'salary_structure',
      settings: {
        behaviour: {
          pfBehaviour: { includeInPfWages: true },
          esiBehaviour: { includeInEsiWages: false },
          ptBehaviour: { includeInPtWages: false },
          lwfBehaviour: { includeInLwfWages: false },
          taxBehaviour: {
            taxExemptInOldRegime: true,
            taxExemptInNewRegime: false,
            taxExemptionSection: 'Section 10',
          },
          arrearBehaviour: {
            considerInArrear: false,
            allowNegative: false,
          },
          prorationBehaviour: {
            supportProration: true,
          },
          displayBehaviour: {
            showZero: false,
            visibleToEmployee: true,
            displayName: 'Leave Travel Allowance',
          },
        },
        earningDetails: {
          payType: 'EARNING_FIXED_TYPE',
        },
      },
    };
  } else {
    return {
      id: i.toString(),
      name: 'HRA',
      displayName: 'Home Rent Allowance',
      description:
        'HRA is a component of the salary provided by the employer to the employee to meet the cost of living in rented accommodation.',
      category: 'EARNINGS_CATEGORY',
      isActive: false,
      source: 'salary_structure',
      settings: {
        behaviour: {
          pfBehaviour: { includeInPfWages: false },
          esiBehaviour: { includeInEsiWages: true },
          ptBehaviour: { includeInPtWages: false },
          lwfBehaviour: { includeInLwfWages: false },
          taxBehaviour: {
            taxExemptInOldRegime: true,
            taxExemptInNewRegime: true,
            taxExemptionSection: 'Section 12',
          },
          arrearBehaviour: {
            considerInArrear: true,
            allowNegative: false,
          },
          prorationBehaviour: {
            supportProration: false,
          },
          displayBehaviour: {
            showZero: false,
            visibleToEmployee: true,
            displayName: 'Home Rent Allowance',
          },
        },
        earningDetails: {
          payType: 'EARNING_FIXED_TYPE',
        },
      },
    };
  }
});

export const DeductionComponentsList: DeductionsComponentType[] = Array.from(
  { length: 25 },
  (_, i) => {
    if (i % 2 === 0) {
      return {
        id: i.toString(),
        name: 'Basic',
        displayName: 'Basic',
        description:
          'The basic salary is the base income of an employee, comprising of 35-50% of the total salary.',
        category: 'DEDUCTIONS_CATEGORY',
        isActive: true,
        source: 'salary_structure',
        settings: {
          behaviour: {
            pfBehaviour: { includeInPfWages: false },
            esiBehaviour: { includeInEsiWages: false },
            ptBehaviour: { includeInPtWages: false },
            lwfBehaviour: { includeInLwfWages: false },
            taxBehaviour: {
              taxExemptInOldRegime: true,
              taxExemptInNewRegime: false,
              taxExemptionSection: 'Section 10',
            },
            arrearBehaviour: {
              considerInArrear: false,
              allowNegative: false,
            },
            prorationBehaviour: {
              supportProration: false,
            },
            displayBehaviour: {
              showZero: false,
              visibleToEmployee: true,
              displayName: 'Basic',
            },
          },
          deductionDetails: {
            deductionType: deductionType.DEDUCTION_ADHOC_TYPE.value,
            deductFrom: deductFrom.DEDUCT_FROM_GROSS_TYPE.value,
          },
        },
      };
    } else {
      return {
        id: i.toString(),
        name: 'HRA',
        displayName: 'Home Rent Allowance',
        description:
          'HRA is a component of the salary provided by the employer to the employee to meet the cost of living in rented accommodation.',
        category: 'DEDUCTIONS_CATEGORY',
        isActive: false,
        source: 'salary_structure',
        settings: {
          behaviour: {
            pfBehaviour: { includeInPfWages: false },
            esiBehaviour: { includeInEsiWages: true },
            ptBehaviour: { includeInPtWages: false },
            lwfBehaviour: { includeInLwfWages: false },
            taxBehaviour: {
              taxExemptInOldRegime: true,
              taxExemptInNewRegime: true,
              taxExemptionSection: 'Section 12',
            },
            arrearBehaviour: {
              considerInArrear: true,
              allowNegative: false,
            },
            prorationBehaviour: {
              supportProration: false,
            },
            displayBehaviour: {
              showZero: false,
              visibleToEmployee: true,
              displayName: 'Home Rent Allowance',
            },
          },
          deductionDetails: {
            deductionType: deductionType.DEDUCTION_ADHOC_TYPE.value,
            deductFrom: deductFrom.DEDUCT_FROM_GROSS_TYPE.value,
          },
        },
      };
    }
  },
);
