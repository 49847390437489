import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import {
  PayrollCreateOrUpdateEarningComponentResponse,
  PayrollCreateOrUpdateEarningComponentVariables,
} from '../types';

export const createSalaryComponentMutation = gql`
  mutation PayrollCreateSalaryComponent(
    $name: String!
    $displayName: String!
    $category: SalaryComponentCategory!
    $settings: PayrollSalaryComponentSettingsInput!
    $description: String
  ) {
    payrollCreateSalaryComponent(
      name: $name
      displayName: $displayName
      category: $category
      settings: $settings
      description: $description
    ) {
      code
      data {
        id
      }
      message
      success
    }
  }
`;

const createSalaryComponent = (queryVariables: PayrollCreateOrUpdateEarningComponentVariables) => {
  return graphQLApi<
    PayrollCreateOrUpdateEarningComponentResponse,
    PayrollCreateOrUpdateEarningComponentVariables
  >({
    query: createSalaryComponentMutation,
    queryVariables,
  });
};

export default createSalaryComponent;
