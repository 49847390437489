import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { Input } from '../Form/Input';
import SearchResultsView from './components/SearchResultsView';

type AutoCompleteProps<T> = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'value'
> & {
  api: (search: string) => Promise<T[]>;
  value?: string;
  onChange?: (item: T) => void;
  onClearSelection?: () => void;
  itemToLabel: (item: T) => string;
  itemToKey: (item: T) => string | number;
  itemToDescription?: (item: T) => string;
};

const AutoCompleteV1 = <T,>({
  api,
  value,
  onChange,
  onClearSelection,
  itemToLabel,
  itemToKey,
  itemToDescription,
  ...searchInputProps
}: AutoCompleteProps<T>) => {
  const [searchTerm, setSearchTerm] = useState(value || '');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const search = useDebouncedValue(searchTerm);

  const { isFetching, data } = useQuery({
    queryKey: [search],
    enabled: search !== value,
    queryFn: () => (search ? api(search) : Promise.resolve([])),
  });

  const handleItemSelect = (item: T) => {
    onChange?.(item);
    setSearchTerm(itemToLabel(item));
    setIsDropdownOpen(false);
  };

  // Floating UI setup
  const { refs, floatingStyles, context } = useFloating({
    open: isDropdownOpen,
    onOpenChange(open, event) {
      setIsDropdownOpen(open);
    },
    middleware: [offset(8), flip(), shift()],
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    strategy: 'fixed',
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  // Handle input changes
  const handleInputChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      setSearchTerm(newValue as string);
      if (!newValue) {
        onClearSelection?.();
        setIsDropdownOpen(false);
      } else {
        setIsDropdownOpen(true);
      }
    },
    [onClearSelection],
  );

  const searchItems = data ?? [];

  return (
    <div className="relative w-full">
      <Input
        ref={refs.setReference}
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        className="w-full p-2 border rounded"
        {...searchInputProps}
        {...getReferenceProps()}
      />

      {isDropdownOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            className="border border-surfaceBorderGrayNormal rounded-lg bg-surfaceBackgroundGrayModerate shadow-lg z-[9999999]">
            <SearchResultsView
              isFetching={isFetching}
              searchItems={searchItems}
              searchTerm={search}
              onSelectItem={handleItemSelect}
              itemToLabel={itemToLabel}
              itemToKey={itemToKey}
              itemToDescription={itemToDescription}
              value={value}
            />
          </div>
        </FloatingPortal>
      )}
    </div>
  );
};

export default AutoCompleteV1;
