export const routePaths = Object.freeze({
  uiDemo: '/uiDemo',
  insurance: {
    admin: {
      root: '/insurance/admin',
      introduction: '/insurance/admin/introduction',
      plan: '/insurance/admin/plan',
      estimate: '/insurance/admin/estimate',
      finalize: '/insurance/admin/finalize',
      employee: '/insurance/admin/finalize/employee/:employeeId',
      details: '/insurance/admin/details',
      renewal: '/insurance/admin/renewal',
      organizationAndEmployeeRenewal: '/insurance/admin/organizationAndEmployeeRenewal',
      newEndorsements: '/insurance/admin/newEndorsements',
    },
    user: {
      root: '/insurance/user',
      details: '/insurance/user/insuranceDetails',
      notEligible: '/insurance/user/noteligible',
      employeeDetails: '/insurance/user/employeeDetails',
    },
  },
  people: '/people',
  settings: {
    slackSettings: '/slack-settings',
    slackActivation: '/slack-settings/activation',
    slackAuthorize: '/slack-settings/authorize',
    freshteamsSettings: '/freshteam-settings',
    whatsappSettings: '/whatsapp-settings',
    freshteamsActivation: '/freshteam-settings/activation',
    jupiterSettings: '/jupiter-settings',
    camsIntro: '/cams-intro',
    camsSettings: '/cams-settings',
    klaar: {
      settings: '/klaar-settings',
      activation: '/klaar-settings/activation',
    },
    zoho: {
      introduction: '/zoho/introduction',
      home: '/zoho',
      authorize: '/zoho/authorize',
      preconditions: '/zoho/introduction/pre-conditions',
      config: {
        root: '/zoho/config',
        employeeTypeSetup: '/zoho/config/employee-type-setup',
        employeeFieldsSetup: '/zoho/config/employee-fields-setup',
        employeeDismissalTypeSetup: '/zoho/config/employee-dismissal-setup',
      },
    },
    springVerify: {
      settings: '/spring-verify-settings',
      activation: '/spring-verify-settings/activation',
      deactivation: '/spring-verify-settings/deactivation',
    },
    jibble: {
      introduction: '/jibble/introduction',
      accountConfirmation: '/jibble/confirmation',
      onboarding: {
        activation: '/jibble/onboarding/activation',
        settingsConfiguration: '/jibble/onboarding/settings',
        syncEmployees: '/jibble/onboarding/sync-employees',
      },
      manage: '/jibble/manage',
      settings: '/jibble/settings',
      employees: '/jibble/employees',
      attendanceReport: '/jibble/attendance-report',
    },
    pazcare: {
      settings: '/pazcare-settings',
      activation: '/pazcare-settings/activation',
      deactivation: '/pazcare-settings/deactivation',
    },
    knitHrms: {
      root: '/hrms/:knitHrmsApp',
      introduction: '/hrms/:knitHrmsApp/introduction',
      manage: '/hrms/:knitHrmsApp/manage',
      integration: '/hrms/:knitHrmsApp/integrate',
      settings: {
        root: '/hrms/:knitHrmsApp/settings',
        entitySelection: '/hrms/:knitHrmsApp/settings/entity-selection',
        fields: '/hrms/:knitHrmsApp/settings/fields',
        employeeContractorMapping: '/hrms/:knitHrmsApp/settings/employee-contractor-mapping',
        syncConfirmation: '/hrms/:knitHrmsApp/settings/confirmation',
        syncInProgress: '/hrms/:knitHrmsApp/settings/in-progress',
        disableIntegration: '/hrms/:knitHrmsApp/settings/disable',
      },
    },
    darwinbox: {
      root: '/hrms/darwinbox',
      introduction: '/hrms/darwinbox/introduction',
      manage: '/hrms/darwinbox/manage',
      integration: '/hrms/darwinbox/integrate',
      settings: {
        root: '/hrms/darwinbox/settings',
        entitySelection: '/hrms/darwinbox/settings/entity-selection',
        fields: '/hrms/darwinbox/settings/fields',
        employeeContractorMapping: '/hrms/darwinbox/settings/employee-contractor-mapping',
        syncConfirmation: '/hrms/darwinbox/settings/confirmation',
        syncInProgress: '/hrms/darwinbox/settings/in-progress',
        disableIntegration: '/hrms/darwinbox/settings/disable',
      },
    },
    hibob: {
      root: '/hrms/hibob',
      introduction: '/hrms/hibob/introduction',
      manage: '/hrms/hibob/manage',
      integration: '/hrms/hibob/integrate',
      settings: {
        root: '/hrms/hibob/settings',
        entitySelection: '/hrms/hibob/settings/entity-selection',
        fields: '/hrms/hibob/settings/fields',
        employeeContractorMapping: '/hrms/hibob/settings/employee-contractor-mapping',
        syncConfirmation: '/hrms/hibob/settings/confirmation',
        syncInProgress: '/hrms/hibob/settings/in-progress',
        disableIntegration: '/hrms/hibob/settings/disable',
      },
    },
    worq: {
      root: '/hrms/worq',
      introduction: '/hrms/worq/introduction',
      manage: '/hrms/worq/manage',
      integration: '/hrms/worq/integrate',
      settings: {
        root: '/hrms/worq/settings',
        entitySelection: '/hrms/worq/settings/entity-selection',
        fields: '/hrms/worq/settings/fields',
        employeeContractorMapping: '/hrms/worq/settings/employee-contractor-mapping',
        syncConfirmation: '/hrms/worq/settings/confirmation',
        syncInProgress: '/hrms/worq/settings/in-progress',
        disableIntegration: '/hrms/worq/settings/disable',
      },
    },
    workline: {
      root: '/hrms/workline',
      introduction: '/hrms/workline/introduction',
      manage: '/hrms/workline/manage',
      integration: '/hrms/workline/integrate',
      settings: {
        root: '/hrms/workline/settings',
        entitySelection: '/hrms/workline/settings/entity-selection',
        fields: '/hrms/workline/settings/fields',
        employeeContractorMapping: '/hrms/workline/settings/employee-contractor-mapping',
        syncConfirmation: '/hrms/workline/settings/confirmation',
        syncInProgress: '/hrms/workline/settings/in-progress',
        disableIntegration: '/hrms/workline/settings/disable',
      },
    },
    rxIntegration: {
      root: '/razorpayx-integration',
      preCheck: '/razorpayx-integration/pre-check',
      activate: '/razorpayx-integration/activate',
    },
  },
  partnerFlexiBenefits: {
    user: {
      employeeDeclarationForm: '/partner-flexi-benefits/employee/:employeeId',
    },
    admin: {
      info: '/partner-flexi-benefits/admin/info',
      connectedStatus: '/partner-flexi-benefits/admin/connectedStatus',
      manage: '/partner-flexi-benefits/admin/manage',
      scheduleACall: '/partner-flexi-benefits/admin/schedule-call',
      actionItems: '/partner-flexi-benefits/admin/action-items',
      kycVerification: '/partner-flexi-benefits/admin/kyc-verification',
    },
  },
  reports: {
    varianceReport: '/reports/varianceReport',
    taxReport: '/reports/taxReport',
    bonusReport: '/reports/bonus',
    esicRegistrationDashboard: '/reports/esicRegistration',
    pfRegistrationDashboard: '/reports/pfRegistration',
    payslip: '/reports/payslipNew',
  },
  companyOnboarding: {
    root: '/company-onboarding',
    companyDetails: '/company-onboarding/details',
    gstinPrefill: '/company-onboarding/details/gstin-prefill',
    panPrefill: '/company-onboarding/details/pan-prefill',
    payrollDate: '/company-onboarding/payroll-date',
    salaryStructure: '/company-onboarding/salary-structure',
    compliance: {
      root: '/company-onboarding/compliance',
      tds: '/company-onboarding/compliance/tds',
      pt: '/company-onboarding/compliance/professional-tax',
      pf: '/company-onboarding/compliance/pf',
      esic: '/company-onboarding/compliance/esic',
    },
  },
  onboarding: {
    root: '/onboarding',
    companyDetails: {
      root: '/onboarding/details',
      basicDetails: '/onboarding/details/basic-details',
      panPrefill: '/onboarding/details/pan-prefill',
      verifyDetails: '/onboarding/details/verify-details',
    },
  },
  employeeOnboarding: {
    root: '/employee-onboarding',
    employeeAnnualCompensation: '/employee-onboarding/employeeAnnualCompensation/:employeeId',
    advanceSalary: '/employee-onboarding/advanceSalary/:employeeId',
  },
  emploteeDetails: {
    root: '/employeeDetail?userId=',
  },
  bulkUploads: {
    root: '/bulk-uploads',
    initiate: '/bulk-uploads/initiate',
    preview: '/bulk-uploads/preview',
    bulkAdditionDeductionLOP: {
      root: '/bulk-uploads/addition-deduction-lop',
      fileUpload: '/bulk-uploads/addition-deduction-lop/file-upload',
      previewScreen: '/bulk-uploads/addition-deduction-lop/preview',
    },
    bulkSalaryRevision: {
      root: '/bulk-uploads/salary-revision',
      fileUpload: '/bulk-uploads/salary-revision/file-upload',
      previewScreen: '/bulk-uploads/salary-revision/preview',
    },
  },
  integrations: {
    root: '/integrations',
    v2: '/integrations/v2',
  },
  help: {
    root: '/help',
  },
  admin: {
    bankSelection: '/compliance-admin/compliance-bank-payment-mapping',
  },
  loans: {
    viewLoans: '/loans/view',
    createLoan: '/loans/create',
    manageLoan: '/loans/manage',
    skipEmi: '/loans/skip-emi',
    loanRecovery: '/loans/recovery',
    loanTenureChange: '/loans/change-tenure',
  },
  login: {
    otp: '/verify-2fa-otp',
    twoFactorOrgSetting: '/two-factor-org-setting',
  },
  accessDenied: '/403',
  unknown: '/404',
  runpayroll: '/run-payroll',
  viewForm16: '/viewForm16',
  kyc: {
    root: '/kyc',
    bankDetails: '/kyc/bank-details',
    bankDetailsManual: '/kyc/bank-details/manual',
    userSelection: '/kyc/user-selection',
    documentsUpload: '/kyc/documents-upload',
    success: '/kyc/success',
    companyDetailsVerification: {
      root: '/kyc/company-details-verification',
      gstin: '/kyc/company-details-verification/gstin',
      cinOrLlpin: '/kyc/company-details-verification/cin-or-llpin',
      manual: '/kyc/company-details-verification/manual',
      addAddress: '/kyc/company-details-verification/add-address',
      pan: '/kyc/company-details-verification/pan',
    },
  },
  bonus: {
    root: '/bonus',
    view: '/bonus/view',
    create: '/bonus/create',
    edit: '/bonus/edit',
    bonusTypeSetting: '/bonusTypeSetup',
  },
  approvalWorkflows: {
    workflow: {
      root: '/workflow',
      editPayroll: '/workflow/edit-payroll',
      finalizePayroll: '/workflow/finalize-payroll',
      salaryRevision: '/workflow/salary-revision',
    },
    myRequests: {
      root: '/my-requests',
      editPayroll: {
        root: '/my-requests/edit-payroll',
        pendingApprovals: '/my-requests/edit-payroll/pending-approvals',
        initiatedByMe: '/my-requests/edit-payroll/initiated-by-me',
        completed: '/my-requests/edit-payroll/completed',
      },
      finalizePayroll: {
        root: '/my-requests/finalize-payroll',
        pendingApprovals: '/my-requests/finalize-payroll/pending-approvals',
        initiatedByMe: '/my-requests/finalize-payroll/initiated-by-me',
        completed: '/my-requests/finalize-payroll/completed',
      },
      salaryRevision: {
        root: '/my-requests/salary-revision',
        pendingApprovals: '/my-requests/salary-revision/pending-approvals',
        initiatedByMe: '/my-requests/salary-revision/initiated-by-me',
        completed: '/my-requests/salary-revision/completed',
      },
      // more to come
    },
  },
  flexibleBenefits: {
    root: '/flexible-benefits',
    declaration: '/flexible-benefits/declarations',
    settings: '/flexible-benefits/settings',
    settingsGeneral: '/flexible-benefits/settings/general',
    settingsDeclaration: '/flexible-benefits/settings/declaration',
    settingsProofUpload: '/flexible-benefits/settings/proof-upload',
    settingsWindowAdavanced: '/flexible-benefits/settings/advanced',
  },
  customReport: {
    root: '/reports/custom-report',
  },
  nps: {
    root: '/nps',
    settings: '/nps/settings',
    declaration: '/nps/declaration',
  },
  fnf: {
    root: '/newFnf',
    initiate: '/newFnf/initiate',
    releaseNetPay: '/newFnf/release-net-pay',
  },
  taxDeclarationAndProofSettings: {
    root: '/tax-declaration-and-proof',
    settings: '/tax-declaration-and-proof/settings',
    settingsGeneral: '/tax-declaration-and-proof/settings/general',
    settingsDeclaration: '/tax-declaration-and-proof/settings/declaration',
    settingsProofUpload: '/tax-declaration-and-proof/settings/proof-upload',
    settingsCustomWindow: '/tax-declaration-and-proof/settings/custom',
  },
  payrollAiAssistant: '/tax-optimizer',
  payrollSetupSettings: {
    root: '/payroll-setup',
  },
  orgSettings: {
    apiAccess: '/settings/api-access',
  },
  salaryComponents: {
    root: '/salary-components',
    earningsListView: '/salary-components/earnings',
    deductionsListView: '/salary-components/deductions',
    nonPayableBenefitsListView: '/salary-components/non-payable-benefits',
    perquisitesListView: '/salary-components/perquisites',
    create: {
      earnings: {
        root: '/salary-components/create-earnings',
        generalDetails: '/salary-components/create-earnings/general-details',
        taxationDetails: '/salary-components/create-earnings/taxation-details',
        review: '/salary-components/create-earnings/review',
      },
      deductions: {
        root: '/salary-components/create-deductions',
        generalDetails: '/salary-components/create-deductions/general-details',
        payAndTaxDetails: '/salary-components/create-deductions/taxation-details',
        review: '/salary-components/create-deductions/review',
      },
    },
    edit: {
      earnings: {
        root: '/salary-components/edit-earnings',
        generalDetails: '/salary-components/edit-earnings/general-details',
        taxationDetails: '/salary-components/edit-earnings/taxation-details',
        review: '/salary-components/edit-earnings/review',
      },
      deductions: {
        root: '/salary-components/edit-deductions',
        generalDetails: '/salary-components/edit-deductions/general-details',
        payAndTaxDetails: '/salary-components/edit-deductions/taxation-details',
        review: '/salary-components/edit-deductions/review',
      },
      predefinedComponent: '/salary-components/edit-predefined-component',
    },
  },
});

export function getEditEmployeeInsuranceDetailsRoute(employeeId: number) {
  return routePaths.insurance.admin.employee.replace(':employeeId', '' + employeeId);
}
