import {
  ActionList,
  ActionListItem,
  Box,
  ExternalLinkIcon,
  Link,
  SearchIcon,
  Spinner,
  Text,
} from '@razorpay/blade/components';
import React from 'react';

const EmptySearchView = () => {
  return (
    <Box padding="spacing.4">
      <Text>Please enter something to search</Text>
    </Box>
  );
};

const NoSearchResultsView = () => {
  return (
    <Box
      padding="spacing.5"
      display="grid"
      flexDirection="column"
      gap="spacing.5"
      placeItems="center">
      <SearchIcon size="medium" color="interactive.icon.gray.muted" />
      <Box display="grid" flexDirection="column" gap="spacing.3" placeItems="center">
        <Text weight="semibold">No results found</Text>
        <Text size="small" color="surface.text.gray.subtle">
          {/* TODO: Update copy once available */}
          Add description of what the user can search for here.
        </Text>
      </Box>
      {/* TODO: Add link once available */}
      <Link icon={ExternalLinkIcon} iconPosition="right">
        Help
      </Link>
    </Box>
  );
};

const ItemsLoader = () => {
  return (
    <Box padding="spacing.6">
      <Spinner accessibilityLabel="Loading data" />
    </Box>
  );
};

type SearchResultsViewProps<T> = {
  isFetching: boolean;
  searchTerm: string;
  value?: string;
  searchItems: T[];
  onSelectItem: (searchItem: T) => void;
  itemToLabel: (item: T) => string;
  itemToKey: (item: T) => string | number;
  itemToDescription?: (item: T) => string;
};

const SearchResultsView = <T,>({
  isFetching,
  searchTerm,
  value,
  searchItems,
  onSelectItem,
  itemToLabel,
  itemToKey,
  itemToDescription,
}: SearchResultsViewProps<T>) => {
  if (isFetching) {
    return <ItemsLoader />;
  }
  if (!searchTerm.trim() || value === searchTerm) {
    return null;
  }
  if (searchItems.length === 0) {
    return <NoSearchResultsView />;
  }

  return (
    <ActionList>
      {searchItems.map((item, index) => (
        <ActionListItem
          key={index}
          title={itemToLabel(item)}
          value={itemToKey(item).toString()}
          onClick={() => onSelectItem(item)}
          description={itemToDescription ? itemToDescription(item) : undefined}
        />
      ))}
    </ActionList>
  );
};

export default SearchResultsView;
