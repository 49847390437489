import { Box, EditIcon, Link, Text } from '@razorpay/blade/components';
import React from 'react';
import { StatusIcon } from '..';
import { ReviewSectionHeaderProps, ReviewSectionItemProps } from './types';

const ReviewSectionHeader = ({ title, subtitle, onEditClick }: ReviewSectionHeaderProps) => (
  <Box display="flex" alignItems="center" justifyContent="space-between">
    <Box display="flex" gap="spacing.2" alignItems="center">
      <Text weight="semibold">{title}</Text>
      <Text color="surface.text.gray.muted">{subtitle}</Text>
    </Box>
    {onEditClick ? (
      <Link
        variant="button"
        icon={EditIcon}
        onClick={onEditClick}
        accessibilityLabel={title + ' ' + subtitle}>
        Edit
      </Link>
    ) : null}
  </Box>
);

const ReviewSectionItem = ({ label, status = 'success', value }: ReviewSectionItemProps) => (
  <Box
    display="flex"
    gap={{ base: 'spacing.4', m: 'spacing.9' }}
    alignItems="flex-start"
    flex="1"
    flexDirection={{ base: 'column', m: 'row' }}>
    <Box display="flex" gap="spacing.3" alignItems="center" minWidth="300px">
      <StatusIcon status={status} />
      <Text color="surface.text.gray.muted">{label}</Text>
    </Box>
    {typeof value === 'string' ? (
      <Text testID={label} color="surface.text.gray.subtle">
        {value}
      </Text>
    ) : (
      value
    )}
  </Box>
);

const ReviewSectionItemContainer = ({ children }: React.PropsWithChildren<{}>) => (
  <Box display="flex" flexDirection="column" gap="14px" marginTop="spacing.7">
    {children}
  </Box>
);

const ReviewSection = ({ children }: React.PropsWithChildren<{}>) => (
  <Box
    borderRadius="large"
    padding="spacing.7"
    borderColor="surface.border.gray.subtle"
    borderWidth="thin">
    {children}
  </Box>
);
const ReviewSectionContainer = ({ children }: React.PropsWithChildren<{}>) => (
  <Box display="flex" flexDirection="column" gap="spacing.7">
    {children}
  </Box>
);

export {
  ReviewSection,
  ReviewSectionContainer,
  ReviewSectionHeader,
  ReviewSectionItem,
  ReviewSectionItemContainer,
};
