import { Box } from '@razorpay/blade/components';
import api from 'api';
import AutoCompleteV1 from 'components/ui/AutoComplete/AutoCompleteV1';
import {
  ReviewSection,
  ReviewSectionHeader,
  ReviewSectionItem,
  ReviewSectionItemContainer,
} from 'components/WizardViews/components/review';
import React, { useState } from 'react';
import { SalaryComponent } from '../types';

type ComponentWidgetV1Props = {
  name?: string;
};

const ComponentWidgetV1 = ({ name }: ComponentWidgetV1Props) => {
  const [selectedComponent, setSelectedComponent] = useState<SalaryComponent | null>(null);
  const taxBehaviour = selectedComponent?.settings.behaviour.taxBehaviour;

  return (
    <div>
      <AutoCompleteV1
        name={name}
        id="componentName"
        placeholder="Select component"
        // TODO: Add data url once its available
        api={(search) => api.completions.getAdhocComponents({ search, dataUrl: '' })}
        itemToKey={(item) => item.name}
        itemToLabel={(item) => item.name}
        itemToDescription={(item) => item.description || ''}
        // value={value}
        onChange={(item) => setSelectedComponent(item)}
        onClearSelection={() => setSelectedComponent(null)}
      />
      {selectedComponent ? (
        <Box paddingTop="spacing.4">
          <ReviewSection>
            <ReviewSectionHeader title="Component" subtitle="details" />
            <ReviewSectionItemContainer>
              {taxBehaviour?.taxExemptInOldRegime && (
                <ReviewSectionItem
                  label="Tax exempted in Old Regime"
                  value={taxBehaviour?.taxExemptInOldRegime ? 'Yes' : 'No'}
                />
              )}
              {taxBehaviour?.taxExemptInNewRegime && (
                <ReviewSectionItem
                  label="Tax exempted in New Regime"
                  value={taxBehaviour?.taxExemptInNewRegime ? 'Yes' : 'No'}
                />
              )}
              <ReviewSectionItem
                label="Exemption under section"
                value={taxBehaviour?.taxExemptionSection}
              />
            </ReviewSectionItemContainer>
          </ReviewSection>
        </Box>
      ) : null}
    </div>
  );
};

export default ComponentWidgetV1;
