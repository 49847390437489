import { ModalActions } from 'components/SalaryComponents/constants';

const updateComponentStatus = (
  componentId?: string,
  action?: keyof typeof ModalActions,
): Promise<{ success: boolean }> => {
  // TODO Update this with GQL query

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ success: true });
    }, 1000);
  });
};

export default updateComponentStatus;
