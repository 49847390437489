import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import {
  PayrollCreateOrUpdateEarningComponentResponse,
  PayrollCreateOrUpdateEarningComponentVariables,
} from '../types';

export const updateSalaryComponentMutation = gql`
  mutation PayrollUpdateSalaryComponent(
    $id: String!
    $displayName: String!
    $settings: PayrollSalaryComponentSettingsInput!
    $description: String
  ) {
    payrollUpdateSalaryComponent(
      id: $id
      displayName: $displayName
      settings: $settings
      description: $description
    ) {
      code
      data {
        id
      }
      message
      success
    }
  }
`;

const updateSalaryComponent = (queryVariables: PayrollCreateOrUpdateEarningComponentVariables) => {
  return graphQLApi<
    PayrollCreateOrUpdateEarningComponentResponse,
    PayrollCreateOrUpdateEarningComponentVariables
  >({
    query: updateSalaryComponentMutation,
    queryVariables,
  });
};

export default updateSalaryComponent;
