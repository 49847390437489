import { Box } from '@razorpay/blade/components';
import bgIllustration from 'assets/bg_illustration.svg';
import React from 'react';
import styled from 'styled-components';

export const FormWrapper = styled.form`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

const IllustrationContainer = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ theme }) => theme.spacing[8]}px;
  background-image: url(${bgIllustration});
  background-size: contain;
  background-attachment: fixed;
  background-repeat: no-repeat;
`;

export const StepContentWrapper = ({ children }: React.PropsWithChildren<{}>) => (
  <IllustrationContainer>
    <Box maxWidth="700px" width={{ base: '100%', m: '700px' }} marginX="auto">
      {children}
    </Box>
  </IllustrationContainer>
);
