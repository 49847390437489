import { routePaths } from 'components/Routes/data';
import { EarningPayType } from 'components/SalaryComponents/types';
import { EarningsFormData } from './types';
import { ValidationSchema } from 'forms';

export const earningSteps = {
  step1: 0,
  step2: 1,
  step3: 2,
};

export const deductionsSteps = {
  step1: 0,
  step2: 1,
  step3: 2,
};

export const createEarningsRouteToStepMap = {
  [routePaths.salaryComponents.create.earnings.generalDetails]: earningSteps.step1,
  [routePaths.salaryComponents.create.earnings.taxationDetails]: earningSteps.step2,
  [routePaths.salaryComponents.create.earnings.review]: earningSteps.step3,
};

export const editEarningsRouteToStepMap = {
  [routePaths.salaryComponents.edit.earnings.generalDetails]: earningSteps.step1,
  [routePaths.salaryComponents.edit.earnings.taxationDetails]: earningSteps.step2,
  [routePaths.salaryComponents.edit.earnings.review]: earningSteps.step3,
};

export const earningPayTypes: Record<
  EarningPayType,
  {
    label: string;
    description: string;
  }
> = {
  EARNING_FIXED_TYPE: {
    label: 'Monthly (fixed)',
    description: 'Paid every month. It is included in the salary structure.',
  },
  EARNING_ADHOC_TYPE: {
    label: 'Ad hoc',
    description: 'Paid only as required. It is not a part of the salary structure.',
  },
  EARNING_VARIABLE_TYPE: {
    label: 'Variable',
    description:
      'Paid on a recurring basis. It is a part of the salary structure. Eg: Performance bonus.',
  },
};

export const deductionType = {
  DEDUCTION_TYPE_RECURRING: {
    value: 'DEDUCTION_TYPE_RECURRING',
    label: 'Recurring (Monthly)',
    description: 'Deduction will be on a monthly basis from gross pay.',
  },
  DEDUCTION_ADHOC_TYPE: {
    value: 'DEDUCTION_ADHOC_TYPE',
    label: 'Ad hoc',
    description: 'You can add this deduction one time during payroll.',
  },
};

export const deductFrom = {
  DEDUCT_FROM_GROSS_TYPE: {
    value: 'DEDUCT_FROM_GROSS_TYPE',
    label: 'Gross Pay',
    description: 'Deduction will be on a monthly basis from gross pay.',
  },
  DEDUCT_FROM_NET_TYPE: {
    value: 'DEDUCT_FROM_NET_TYPE',
    label: 'Net Pay',
    description: 'Deduction applied directly to Net Pay',
  },
};

export const createDeductionsRouteToStepMap = {
  [routePaths.salaryComponents.create.deductions.generalDetails]: deductionsSteps.step1,
  [routePaths.salaryComponents.create.deductions.payAndTaxDetails]: deductionsSteps.step2,
  [routePaths.salaryComponents.create.deductions.review]: deductionsSteps.step3,
};

export const editDeductionsRouteToStepMap = {
  [routePaths.salaryComponents.edit.deductions.generalDetails]: deductionsSteps.step1,
  [routePaths.salaryComponents.edit.deductions.payAndTaxDetails]: deductionsSteps.step2,
  [routePaths.salaryComponents.edit.deductions.review]: deductionsSteps.step3,
};

export const links = {
  knowMoreDeductionsTaxability: '',
};

export const earningsTaxSections = [
  { label: 'Section 10(1)', key: 'section_10_1', description: 'Agricultural Income' },
  {
    label: 'Section 10(2)',
    key: 'section_10_2',
    description: 'Amount received by a member from a HUF (Hindu Undivided Family)',
  },
  { label: 'Section 10(10)', key: 'section_10_10', description: 'Gratuity' },
  { label: 'Section 10(10A)', key: 'section_10_10a', description: 'Commutation of Pension' },
  {
    label: 'Section 10(10C)',
    key: 'section_10_10c',
    description: 'Voluntary Retirement Scheme (VRS) compensation',
  },
  {
    label: 'Section 10(14)',
    key: 'section_10_14',
    description:
      'Special Allowances (e.g., Uniform Allowance, Conveyance Allowance, and other allowances)',
  },
];

export const deductionsTaxSections = [
  { label: 'Section 80C', key: 'section_80c', description: 'Deductions on Investments' },
  { label: 'Section 80D', key: 'section_80d', description: 'Medical Insurance Premium' },
  { label: 'Section 80DD', key: 'section_80dd', description: 'Disabled Dependent' },
  { label: 'Section 80DDB', key: 'section_80ddb', description: 'Treatment of Specified Diseases' },
  { label: 'Section 80E', key: 'section_80e', description: 'Education Loan Interest' },
  {
    label: 'Section 80EEB',
    key: 'section_80eeb',
    description: 'Interest on loan taken for the purchase of electric vehicle',
  },
  { label: 'Section 80G', key: 'section_80g', description: 'Donations' },
];

export const knowMoreItems = {
  proration: 'proration',
  arrearCalculation: 'arrear-calculation',
  wageCalculation: 'wage-calculation',
  taxability: 'taxability',
};
export const taxDeductionBehavior = {
  pro_rated: {
    label: 'Prorated',
  },
  instant: {
    label: 'Instant',
  },
};

export const earningsGeneralDetailsStepValidationSchema: ValidationSchema<EarningsFormData> = {
  name: (formData) => {
    if (!formData.name) return 'Component name is required';
    return null;
  },
  displayName: (formData) => {
    if (!formData.displayName) return 'Display name is required';
    return null;
  },
  description: (formData) => {
    if (!formData.description) return 'Description is required';
    return null;
  },
  payType: (formData) => {
    if (!formData.payType) return 'Pay frequency is required';
    return null;
  },
};

export const earningsTaxationDetailsStepValidationSchema: ValidationSchema<EarningsFormData> = {
  taxDeductionBehaviour: (formData) => {
    const payType = formData.payType;
    if (payType === 'EARNING_ADHOC_TYPE' || payType === 'EARNING_VARIABLE_TYPE') {
      if (!formData.taxDeductionBehaviour) return 'Tax deduction behavior is required';
    }
    return null;
  },
};

export const earningsValidationSchema: ValidationSchema<EarningsFormData> = {
  name: (formData) => {
    if (!formData.name) return 'Component name is required';
    return null;
  },
  displayName: (formData) => {
    if (!formData.displayName) return 'Display name is required';
    return null;
  },
  description: (formData) => {
    if (!formData.description) return 'Description is required';
    return null;
  },
  payType: (formData) => {
    if (!formData.payType) return 'Pay frequency is required';
    return null;
  },
  taxDeductionBehaviour: (formData) => {
    const payType = formData.payType;
    if (payType === 'EARNING_ADHOC_TYPE' || payType === 'EARNING_VARIABLE_TYPE') {
      if (!formData.taxDeductionBehaviour) return 'Tax deduction behavior is required';
    }
    return null;
  },
};
