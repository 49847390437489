import {
  DeductionComponentsList,
  EarningComponentsList,
} from 'components/SalaryComponents/__tests__/data';
import {
  ComponentListQueryParams,
  DeductionsComponentType,
  EarningsComponentType,
  SalaryComponentType,
} from 'components/SalaryComponents/types';

const getSalaryComponentsListQuery = (
  componentType: SalaryComponentType,
  params: ComponentListQueryParams,
) => {
  // TODO Update this with GQL query
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: componentType === 'earnings' ? EarningComponentsList : DeductionComponentsList,
      });
    }, 1000);
  });
};

const getSalaryComponentByIdQuery = (id: string) => {
  // TODO Update this with GQL query
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({});
    }, 1000);
  });
};

export const getSalaryComponentDetailsQuery = (
  componentType: string,
  componentId?: string,
): Promise<EarningsComponentType | DeductionsComponentType> => {
  //to be updated with gql query
  return new Promise((resolve, reject) => {
    if (!Boolean(componentId)) {
      reject('Component ID is required');
    } else if (componentId === '-1') {
      //added to test the error handling
      reject('Component ID is incorrect');
    }
    setTimeout(() => {
      resolve(
        (componentType === 'earnings' ? EarningComponentsList : DeductionComponentsList).filter(
          (component) => component.id === componentId,
        )[0],
      );
    }, 1000);
  });
};

const queries = {
  getSalaryComponentsListQuery,
  getSalaryComponentByIdQuery,
  getSalaryComponentDetailsQuery,
};

export default queries;
