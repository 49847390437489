import '../webpack-dynamic-config-change';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { store } from 'reducers';
import Widgets from 'components/Widgets';
import ModalProvider from 'components/ui/Modals/ModalProvider';
import { initSentry, setUserContext as setSentryUserContext } from 'utils/sentry';
import { BrowserRouter } from 'react-router-dom';
import { BladeProvider } from '@razorpay/blade/components';
import { bladeTheme } from '@razorpay/blade/tokens';

initSentry();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
    },
  },
});

interface WidgetMessage {
  elementType: string;
  elementId: string;
  props: any;
}

declare global {
  interface Window {
    payroll: {
      setSentryUserContext: typeof setSentryUserContext;
    };
  }
}

window.payroll = {
  setSentryUserContext,
};

window.addEventListener('message', (event) => {
  if (event.origin !== window.location.origin || !event.data.elementType) return;

  if (!event.data.elementId && !event.data.elementType) {
    return;
  }

  const { elementType, props, elementId }: WidgetMessage = event.data;

  if (document.getElementById(elementId)) {
    ReactDOM.render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Provider store={store}>
              <BladeProvider themeTokens={bladeTheme} colorScheme="dark">
                <ModalProvider>
                  <Widgets elementType={elementType} props={props} />
                </ModalProvider>
              </BladeProvider>
            </Provider>
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </React.StrictMode>,
      document.getElementById(elementId),
    );
  }
});
