import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { isProduction } from 'utils/getEnvironment';

const sensitiveKeys = [
  'token',
  'code',
  'password',
  'redirect',
  'resetCode',
  'Company_Name',
  'Annual_CTC',
  'Manager_Name',
  'Company_Adress',
  'Employee_Name',
  'HR_Name',
  'csrf-token',
];

/**
 * In Sentry's /issues/{id} API response:
 * - metadata.type corresponds to hint.originalException.name
 * - metadata.value corresponds to hint.originalException.message
 *
 * below is the list of errors to be ignored in sentry
 * reference - https://docs.google.com/spreadsheets/d/1h2XFjFpVM1Pb9CaSz8l_l2GKdcMbdHjL8j6WcHC3mm8/edit?gid=0#gid=0
 */
const errorsToBeIgnored = [
  {
    type: 'UnhandledRejection',
    value: /Non-Error promise rejection captured with value: Timeout/,
  },
  {
    type: 'EvalError',
    value:
      /Refused to evaluate a string as JavaScript because 'unsafe-eval' is not an allowed source of script in the following Content Security Policy directive: "script-src/,
  },
  {
    type: 'ReferenceError',
    value: 'wB is not defined',
  },
  {
    type: 'ReferenceError',
    value: 'onModuleDataArrival is not defined',
  },
  {
    type: 'ReferenceError',
    value: 'runCustomize is not defined',
  },
  {
    type: 'TypeError',
    value: 'scrollReadRandom(...) is not a function',
  },
  {
    type: 'ReferenceError',
    value: 'scrollReadRandom is not defined',
  },
  { type: 'TypeError', value: "null is not an object (evaluating 'g.readyState')" },
];

function shouldSkipException({ hint }: { hint: Sentry.EventHint }) {
  //@ts-expect-error - name is present in Error type
  const errorName = hint.originalException?.name || '';

  //@ts-expect-error - message is present in Error type
  const errorMessage = hint.originalException?.message || '';

  const isErrorValueMatching = (value: string | RegExp, errorMessage: string) => {
    return value instanceof RegExp ? value.test(errorMessage) : value === errorMessage;
  };

  const canSkipError = errorsToBeIgnored.some(({ type, value }) => {
    return type === errorName
      ? Array.isArray(value)
        ? value.some((val) => isErrorValueMatching(val, errorMessage))
        : isErrorValueMatching(value, errorMessage)
      : false;
  });

  return !!canSkipError;
}

export function initSentry() {
  if (isProduction()) {
    Sentry.init({
      dsn: 'https://f98b2a23d501494b8fc2744bc0ff8a52@o515678.ingest.sentry.io/6752425',
      integrations: [new BrowserTracing()],
      release: process.env.COMMIT_ID,
      tracesSampleRate: 0,
      beforeSend: (event, hint) => {
        if (shouldSkipException({ hint })) {
          return null;
        }

        // scrub sensitive data from URLs before pushing to sentry
        if (event.request?.url) {
          const url = new URL(event.request.url);
          const params = new URLSearchParams(url.search);
          for (const [key, value] of params) {
            if (sensitiveKeys.includes(key)) {
              params.set(key, 'scrubbed');
            }
          }
          url.search = params.toString();
          event.request.url = url.toString();
        }
        return event;
      },
      // commmunity list of common errors to ignore from https://docs.sentry.io/platforms/javascript/configuration/filtering/
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
      ],
      // commmunity list of common urls to ignore from https://docs.sentry.io/platforms/javascript/configuration/filtering/
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        /apis\.google\.com/i,
      ],
    });
  }
}

export function setUserContext(peopleId: number, organizationId: number | undefined) {
  if (isProduction()) {
    Sentry.setUser({ id: peopleId.toString() });
    Sentry.setContext('organization', {
      id: organizationId,
    });
  }
}

export function captureErrorInSentry(error: any) {
  const erroObj = typeof error === 'string' ? new Error(error) : error;
  Sentry.captureException(erroObj);
}
